import React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'
import SignInUI from 'components/SignInUI'

const SignIn = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { t } = useTranslation('signIn')

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={t('title')}
        description={t('description')}
        keywords={t('keywords')}
      />
      <SignInUI heading={t('heading')} />
    </Layout>
  )
}

export default SignIn

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
